.education-stage {
    @media ($above-mobile-width) {
        label[for="education-type-true-option"] {
            // No label for this field, so align it to the left more
            margin-left: -0.4em !important;
        }
        [data-heading="Name of institution"] {
            word-break: break-word;
            width: 15em;
        }
        [data-heading="FT / PT"] {
            width: 4.5em;
        }
        [data-heading="From"] {
            width: 5.5em;
        }
        [data-heading="To"] {
            width: 5.5em;
        }
        [data-heading="Qualification level"] {
            width: 6em;
        }
        [data-heading="Qualification gained"] {
            word-break: break-word;
            width: 15em;
        }
        [data-heading="Edit"],
        [data-heading="Remove"] {
            width: 2.5em;
            .ms-Button {
                min-width: 2.5em;
                padding: 0;
                .ms-Button-textContainer {
                    display: none;
                }
            }
        }
    }
}
