.employment-stage {
    @media ($above-mobile-width) {
        [data-heading="Name of employer"] {
            word-break: break-word;
            width: 8em;
        }
        [data-heading="Address"] {
            word-break: break-word;
            width: 10em;
        }
        [data-heading="From"],
        [data-heading="To"] {
            width: 5.5em;
        }
        [data-heading="Starting salary"],
        [data-heading="End salary"] {
            width: 5em;
        }
        [data-heading="Reason for leaving"],
        [data-heading="Reference"] {
            word-break: break-word;
            width: 8.5em;
        }
        [data-heading="Main duties"] {
            word-break: break-word;
            width: 11em;
        }
        [data-heading="Edit"],
        [data-heading="Remove"] {
            width: 2.5em;
            .ms-Button {
                min-width: 2.5em;
                padding: 0;
                .ms-Button-textContainer {
                    display: none;
                }
            }
        }
    }
    [data-heading="Main duties"] {
        display: none; // Print-only
    }
}

.print-content .employment-stage {
    [data-heading="Main duties"] {
        display: table-cell; // Show when printing
    }
}
