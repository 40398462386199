@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald);

/* Colours */
$bistech-primary-green: #a6ce39;
$bistech-primary-turquoise: #00b6b0;
$bistech-primary-light-blue: #29aacd;
$bistech-primary-dark-grey: #4d4d4f;
$bistech-primary-off-white: #f5f5f5;
$bistech-secondary-green: #d3df4e;
$bistech-secondary-deep-blue: #0081c6;
$bistech-secondary-purple: #6b3982;
$bistech-secondary-pink: #de3d96;
$bistech-secondary-yellow: #f8d100;
$bistech-secondary-light-grey: #d1d3d4;
$invalid-red: #a4262c;

/* Responsive Breakpoints */
$below-mobile-width: "max-width: 767px";
$above-mobile-width: "min-width: 768px";
$below-mobile-portrait-width: "max-width: 479px";
$above-mobile-portrait-width: "min-width: 480px";
$below-tablet-width: "max-width: 1024px";
$above-tablet-width: "min-width: 1025px";

/* Stage-specific styling */
@import "PersonalDetailsStage";
@import "EducationStage.scss";
@import "TrainingStage.scss";
@import "EmploymentStage.scss";

%control-border {
    border: 1px solid $bistech-primary-dark-grey;
    border-radius: 0;
    &:focus {
        // Focus border colour should override any invalid border colour.
        // As validity is signified by the `invalid` class on the control,
        // `!important` is used to avoid repeating the selector path.
        border-color: $bistech-primary-light-blue !important;
    }
}

html {
    margin: 0;
    padding: 0;
}
body {
    display: flex;
    min-height: 100vh;
    font-size: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Tahoma", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media ($above-tablet-width) {
        background: url(../../Shared/Images/basic-background.jpg), linear-gradient(30deg, $bistech-primary-light-blue, $bistech-primary-light-blue, $bistech-primary-green);
        background-repeat: no-repeat;
        background-size: cover;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
        margin: 0;
    }
    input,
    select,
    textarea {
        @extend %control-border;
        font-family: inherit;
        font-size: 15px;
        color: #222;
        box-shadow: none;
        &:disabled {
            background-color: #eee;
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
    a,
    .link {
        color: $bistech-primary-light-blue;
        &:hover {
            color: darken($bistech-primary-light-blue, 5%);
            text-decoration: underline;
        }
    }
    .link {
        outline: none;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
    }
    .ms-Button--default {
        box-shadow: 0 0.1em #ddd;
        border-color: #999;
        color: #4d4d4f;
        .ms-Button-label {
            font-weight: normal;
            text-transform: uppercase;
            font-size: 15px;
        }
    }
    .ms-Button--primary {
        color: white;
        background-color: $bistech-primary-light-blue;
        border-color: darken($bistech-primary-light-blue, 10%);
        box-shadow: 0 0.1em darken($bistech-primary-light-blue, 12%);
        &:disabled {
            cursor: disabled;
            opacity: 0.6;
        }
        &:focus,
        &:hover {
            background-color: darken($bistech-primary-light-blue, 3%);
            border-color: darken($bistech-primary-light-blue, 10%);
        }
        .ms-Button-label {
            font-weight: normal;
            text-transform: uppercase;
            font-size: 15px;
        }
    }
    table {
        width: 100%;
        margin: 1em 0em;
        border-collapse: collapse;
        outline: 1px solid #ddd;
        tr {
            border-bottom: 1px solid #e0e0e0;
        }
        thead {
            @media screen and ($below-mobile-width) {
                display: none;
            }
            tr {
                border-bottom: 1px solid #e0e0e0;
                background-color: #f9f9f9;
                th {
                    font-weight: 600;
                    font-size: 0.92em;
                }
            }
        }
        tbody {
            @media screen and ($below-mobile-width) {
                display: flex;
                flex-direction: column;
            }
            tr {
                @media screen and ($below-mobile-width) {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    background-color: #f9f9f9;
                }
            }
        }
        th,
        td {
            text-align: left;
            color: #222;
            font-size: 0.92em;
            @media screen and ($below-mobile-width) {
                padding: 0.4em;
                margin-left: 10.6em;
                min-height: 1.3em;
                &::before {
                    content: attr(data-heading);
                    position: absolute;
                    left: 0.8em;
                    max-width: 9em;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-weight: 600;
                }
                &:first-of-type {
                    padding-top: 0.8em;
                }
                &:last-of-type {
                    padding-bottom: 0.8em;
                }
            }
            @media screen and ($above-mobile-width) {
                padding: 0.6em;
            }
            &.invalid {
                border: 2px solid $invalid-red;
                background-color: #ffdede;
            }
        }
        td {
            background-color: #fff;
        }
    }
    .internet-explorer-error,
    noscript {
        background-color: #fff;
        padding: 1em;
        margin: 1em;
        color: $invalid-red;
    }
    #root {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        background-color: #fff;
        @media ($below-tablet-width) {
            width: 100%;
        }
        @media ($above-tablet-width) {
            width: 62vw;
            min-width: 62em;
            box-shadow: 0 0 0.8em #888;
            margin: 0.6em;
        }
        .header {
            display: flex;
            flex-shrink: 1;
            color: white;
            align-items: center;
            background-color: #011425;
            background-image: url(../Images/home-masthead-background_6000.jpg);
            @media (max-width: 3840px) {
                background-image: url(../Images/home-masthead-background_4000.jpg);
            }
            @media (max-width: 2048px) {
                background-image: url(../Images/home-masthead-background_2500.jpg);
            }
            @media (max-width: 1280px) {
                background-image: url(../Images/home-masthead-background_1500.jpg);
            }
            @media ($below-mobile-width) {
                align-items: center;
                justify-content: center;
                background-size: cover;
                background-position-x: 35%;
                padding: 0.7em;
            }
            @media ($above-mobile-width) {
                background-size: cover;
                padding: 1em;
            }
            .branding {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: flex-start;
                .logo {
                    user-select: none;
                    @media ($above-mobile-width) {
                        align-self: flex-start;
                        margin: 0.4em;
                        margin-left: 0.6em;
                        height: 4.5em;
                    }
                    @media ($below-mobile-width) {
                        margin: 0.3em;
                    }
                }
            }
            .title {
                font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                line-height: 1em;
                user-select: none;
                @media ($below-mobile-width) {
                    margin: 0.3em 0.2em;
                    text-align: center;
                    font-size: 1.7em;
                }
                @media ($above-mobile-width) {
                    margin: 0.4em 0.3em;
                    font-size: 2.6em;
                }
            }
        }
        .form {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .main {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #fff;
                @media ($below-mobile-width) {
                    padding: 1.2em;
                }
                @media ($above-mobile-width) {
                    padding: 1.5em;
                }
                .stage-title {
                    font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                    line-height: 1em;
                    padding-bottom: 0.4em;
                    margin: 0.2em 0;
                    color: #4d4d4f;
                    @media ($below-mobile-width) {
                        text-align: center;
                        border-bottom: 5px solid $bistech-primary-light-blue;
                        font-size: 2.2em;
                        margin-bottom: 0.5em;
                    }
                    @media ($above-mobile-width) {
                        font-size: 2.6em;
                        &::after {
                            display: flex;
                            content: "";
                            width: 100%;
                            border-bottom: 7px solid $bistech-primary-light-blue;
                            margin-top: 0.4em;
                        }
                    }
                }
                .stage {
                    display: flex;
                    flex-direction: column;
                    & > p {
                        @media ($below-mobile-width) {
                            text-align: center;
                        }
                    }
                    & > .error {
                        @media ($below-mobile-width) {
                            text-align: center;
                        }
                        @media ($above-mobile-width) {
                            padding: 0.3em;
                        }
                    }
                    .error {
                        padding: 0 0.2em;
                    }
                }
                .error {
                    color: $invalid-red;
                    font-weight: 600;
                    padding: 0.3em;
                }
                .list {
                    @media ($below-mobile-width) {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .add-row-btn {
                        font-size: 14px;
                        padding: 0.7em 0.9em;
                        height: auto;
                        @media ($below-mobile-width) {
                            align-self: center;
                        }
                    }
                }
                .form-row {
                    display: flex;
                    margin: -0.5em 0;
                    @media ($below-mobile-width) {
                        flex-direction: column;
                    }
                    @media ($above-mobile-width) {
                        flex-direction: row;
                        align-items: center;
                        & > * {
                            flex-grow: 1;
                            flex-basis: 50%;
                        }
                    }
                }
            }
            .save-status {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                align-items: center;
                border-top: 1px solid #e8e8e8;
                background-color: #f8f8f8;
                font-size: 0.9em;
                color: #777;
                padding: 0.65em 1.4em;
                .icon {
                    margin-right: 0.5em;
                }
                .save-status-message {
                    flex-grow: 1;
                    margin-right: 0.5em;
                    font-weight: 600;
                }
            }
            .footer {
                display: flex;
                flex-shrink: 0;
                align-items: center;
                border-top: 1px solid #e8e8e8;
                background-color: #f8f8f8;
                justify-content: center;
                @media ($below-mobile-width) {
                    flex-direction: column-reverse;
                    align-items: stretch;
                }
                @media ($above-mobile-width) {
                    flex-direction: row;
                    padding: 1em;
                }
                .progress-bar {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    flex-shrink: 0;
                    @media ($below-mobile-width) {
                        padding: 1.1em;
                        padding-top: 0.8em;
                    }
                    @media ($above-mobile-width) {
                        padding: 0.4em;
                    }
                    .progress-bar-label {
                        font-size: 0.9em;
                        padding: 0.3em 0;
                        padding-top: 0;
                        user-select: none;
                        color: #656565;
                        @media ($below-mobile-width) {
                            text-align: center;
                            margin-bottom: 0.1em;
                        }
                    }
                    .progress-bar-total {
                        display: flex;
                        align-items: flex-start;
                        height: 0.3em;
                        background-color: #e5e5e5;
                        @media ($below-mobile-width) {
                            margin: 0.2em 0.4em;
                        }
                        @media ($above-mobile-width) {
                            margin-bottom: 0.2em;
                        }
                        .progress-bar-progress {
                            background-color: $bistech-primary-light-blue;
                            height: 100%;
                            transition: width ease 0.4s;
                        }
                    }
                }
                .wizard-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    @media ($above-mobile-width) {
                        margin-left: 1em;
                    }
                    @media ($below-mobile-width) {
                        margin-top: 0.8em;
                        margin-bottom: 0.3em;
                        font-size: 1.1em;
                    }
                    .ms-Button {
                        text-transform: uppercase;
                        height: auto;
                        border-radius: 0;
                        background-color: #fff;
                        border-color: #999;
                        color: #4d4d4f;
                        box-shadow: 0 0.1em #ccc;
                        font-size: 1em;
                        @media ($below-mobile-width) {
                            padding: 1em 0.8em;
                        }
                        @media ($above-mobile-width) {
                            padding: 0.8em 0.6em;
                        }
                        &:disabled {
                            border-color: #ddd;
                            box-shadow: 0 0.1em #ddd;
                            cursor: disabled;
                            color: #aaa;
                        }
                        &:focus,
                        &:hover {
                            background-color: darken(#fff, 6%);
                        }
                        .ms-Button-label {
                            font-weight: normal;
                        }
                        &.ms-Button--primary {
                            color: white;
                            border-color: darken($bistech-primary-light-blue, 5%);
                            background-color: $bistech-primary-light-blue;
                            box-shadow: 0 0.1em darken($bistech-primary-light-blue, 12%);
                            &:disabled {
                                cursor: disabled;
                                opacity: 0.6;
                            }
                            &:focus,
                            &:hover {
                                background-color: darken($bistech-primary-light-blue, 4%);
                                border-color: darken($bistech-primary-light-blue, 4%);
                            }
                        }
                    }
                }
            }
        }
        // All field styling moved outside of the selector nesting for `.form` etc. to accommodate varying top-level DOM structures e.g. HRPrintApplicationForm
        .field {
            display: flex;
            flex-direction: column;
            @media ($above-mobile-width) {
                margin: 0.8em 1.5em;
            }
            @media ($below-mobile-width) {
                margin: 0.9em 0.2em;
            }
            &.dropdown-field {
                &.invalid .field-input {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                    &:focus {
                        outline: none !important;
                    }
                }
                .field-input {
                    padding: 0.4em 0.6em;
                    &:focus {
                        outline: 1px solid $bistech-primary-light-blue !important;
                    }
                }
            }
            &.two-option-field {
                @media ($above-mobile-width) {
                    flex-direction: row;
                    align-items: center;
                }
                .field-label {
                    max-width: 25em;
                    @media ($above-mobile-width) {
                        margin-right: 1em;
                        display: inline;
                    }
                }
                &.invalid .options {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                }
                .options {
                    display: flex;
                    align-items: center;
                    .true-option,
                    .false-option {
                        display: flex;
                        padding: 0.5em;
                        align-items: center;
                        margin-left: 0.4em;
                        margin-right: 0.4em;
                        @media ($below-mobile-width) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                        .field-input {
                            width: 1.5em;
                            height: 1.5em;
                            margin: 0;
                            margin-left: 0.3em;
                            cursor: pointer;
                            &:focus {
                                outline-color: $bistech-primary-light-blue !important;
                            }
                        }
                    }
                }
            }
            &.string-field {
                &.invalid .field-input {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                    &:focus {
                        outline: none !important;
                    }
                }
                .field-input {
                    padding: 0.47em 0.6em;
                    &:focus {
                        outline: 1px solid $bistech-primary-light-blue !important;
                    }
                }
            }
            &.search-field {
                &.invalid .field-input:not(.is-active) {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                }
                .field-input.ms-SearchBox {
                    height: 36px;
                    z-index: 998;
                }
                .ms-SearchBox {
                    @extend %control-border;
                    &.is-active::after {
                        border-color: $bistech-primary-light-blue !important;
                        border-radius: 0;
                    }
                }
                &.invalid .ms-SearchBox.is-required::after {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                }
                .focus-zone {
                    position: relative;
                    .focus-div {
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 997;
                    }
                    .callout {
                        position: absolute;
                        left: 0;
                        right: 0;
                        background-color: #fff;
                        z-index: 999;
                        margin: 0.05em;
                        box-shadow: 0 1px 5px #aaa;
                        .suggestion {
                            display: block;
                            padding: 0.5em 0.6em;
                            text-align: left;
                            line-height: 1.1em;
                        }
                        .suggestions-message {
                            display: block;
                            padding: 0.5em;
                            color: #777;
                            font-size: 0.95em;
                            background-color: #f6f6f6;
                        }
                    }
                }
            }
            &.multi-line-string-field {
                &.invalid .field-input {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                    &:focus {
                        outline: none !important;
                    }
                }
                .field-input {
                    padding: 0.5em 0.7em;
                    min-height: 6em;
                    resize: vertical;
                    &:focus {
                        outline-color: $bistech-primary-light-blue !important;
                    }
                }
            }
            &.agreement-field {
                padding: 1.2em;
                background-color: #f2f2f2;
                outline: 1px solid #ddd;
                &.invalid {
                    outline: 2px solid $invalid-red;
                }
                .field-label {
                    display: block;
                    font-weight: normal;
                    margin-bottom: 1em;
                    color: #000;
                }
                .agreement-option {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    .field-input {
                        width: 1.5em;
                        height: 1.5em;
                        flex-shrink: 0;
                        @media ($below-mobile-width) {
                            margin-right: 0.8em;
                        }
                        @media ($above-mobile-width) {
                            margin-right: 0.6em;
                        }
                        &:focus {
                            outline-color: $bistech-primary-light-blue !important;
                        }
                    }
                }
            }
            &.date-field {
                &.invalid .field-input {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                    &:focus {
                        outline: none !important;
                    }
                }
                .field-input {
                    padding: 0.4em;
                    &:focus {
                        outline-color: $bistech-primary-light-blue !important;
                    }
                }
            }
            &.duration-field {
                &.invalid .date-fields .field .field-input {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                }
                .date-fields {
                    display: flex;
                    @media ($below-mobile-width) {
                        flex-direction: column;
                    }
                    @media ($above-mobile-width) {
                        flex-direction: row;
                        align-items: center;
                    }
                    .field {
                        flex-grow: 1;
                        flex-basis: 50%;
                        margin: 0;
                        @media ($below-mobile-width) {
                            margin-bottom: 1em;
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                        @media ($above-mobile-width) {
                            margin-right: 3em;
                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            &.money-field {
                &.invalid .field-input {
                    border: 2px solid $invalid-red;
                    outline-color: $invalid-red !important;
                    &:focus {
                        outline: none !important;
                    }
                }
                .field-input-wrapper {
                    display: flex;
                    align-items: center;
                    .currency-symbol {
                        font-size: 1.1em;
                        margin-right: 0.3em;
                    }
                    .field-input {
                        padding: 0.4em 0.6em;
                        flex-grow: 1;
                        &:focus {
                            outline-color: $bistech-primary-light-blue !important;
                        }
                    }
                }
            }
            &.optional-checkbox-field {
                display: flex;
                flex-direction: row;
                align-items: center;
                .field-label {
                    flex-grow: 0;
                }
                .field-input {
                    width: 1.5em;
                    height: 1.5em;
                    margin-left: 0.4em;
                    &:focus {
                        outline-color: $bistech-primary-light-blue !important;
                    }
                }
            }
            [data-checked="false"] {
                opacity: 0.45; // Fade any unchecked checkbox
            }
            [data-checked="true"] {
                .read-only-checkbox {
                    background-color: #fdfdfd;
                }
            }
            .read-only-checkbox {
                display: flex;
                width: 0.9em;
                height: 0.9em;
                order: -1; // Precede the label
                margin-right: 0.3em;
                background-color: #fff;
                border: 1.5px solid #444;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0.15em;
            }
            .field-label {
                display: flex;
                font-weight: 600;
                flex-grow: 1;
                margin: 0.2em 0;
                @media ($below-mobile-width) {
                    margin-bottom: 0.4em;
                }
                &.required::after {
                    content: "*";
                    color: red;
                    padding-left: 0.2em;
                }
                .follow-up-of-previous-field-icon {
                    display: none; // Only show this icon for HR-facing PDF
                }
            }
            .error {
                margin-top: 0.4em;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .modal-wrapper {
            display: flex;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            justify-content: center;
            background-color: #0008;
            z-index: 99;
            @media ($above-mobile-width) {
                align-items: center;
                padding: 1.5em;
            }
            .modal {
                display: flex;
                position: relative;
                flex-direction: column;
                background-color: #fff;
                box-shadow: 0 0 0.35em #333;
                @media ($below-mobile-width) {
                    flex-grow: 1;
                    padding: 1.2em;
                    padding: 0.8em;
                }
                @media ($above-mobile-width) {
                    flex-basis: 50em;
                    min-height: 10em;
                    max-height: 95vh;
                    padding: 1.5em;
                    padding: 1.5em;
                }
                .modal-header {
                    display: flex;
                    margin-bottom: 1em;
                    flex-shrink: 0;
                    @media ($below-mobile-width) {
                        align-self: flex-start;
                    }
                    .modal-title {
                        font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                        line-height: 1em;
                        user-select: none;
                        text-align: left;
                        @media ($below-mobile-width) {
                            font-size: 1.4em;
                        }
                        @media ($above-mobile-width) {
                            font-size: 2em;
                        }
                    }
                    .modal-close-btn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin: 0.3em;
                    }
                }
                .modal-body {
                    padding: 0;
                    padding-bottom: 1.5em;
                    overflow-y: auto;
                    & > p {
                        @media ($below-mobile-width) {
                            text-align: center;
                        }
                    }
                    & > .error {
                        padding: 1em;
                        margin: 0.2em;
                        background-color: #ffdede;
                        border: 2px solid #a4262c;
                        .error-list {
                            padding: 0;
                            padding-left: 1.2em;
                            margin: 0.3em;
                        }
                    }
                }
                .modal-footer {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    @media ($below-mobile-width) {
                        margin-top: 1em;
                        justify-content: center;
                    }
                    @media ($above-mobile-width) {
                        margin-top: 0.5em;
                        justify-content: flex-end;
                    }
                    & > * {
                        margin: 0.3em 0.4em;
                        padding: 0.8em 1em;
                        height: auto;
                    }
                }
            }
        }
    }
}
